.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none;
}

@media screen and (min-width: 35.5em) {
  .visible-sm {
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .visible-md {
    display: block;
  }
}
@media screen and (min-width: 64em) {
  .visible-lg {
    display: block;
  }
}
@media screen and (min-width: 80em) {
  .visible-xl {
    display: block;
  }
}
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.block-centered {
  margin-left: auto;
  margin-right: auto;
}
