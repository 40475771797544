.icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 1.5rem;
  vertical-align: middle;
  width: 1.5rem;
}
.icon.icon-small {
  height: 0.75rem;
  width: 0.75rem;
}
.icon.icon-close {
  background-image: url("../../../assets/images/icons/close-gray.svg");
  cursor: pointer;
}
.icon.icon-close:hover {
  background-image: url("../../../assets/images/icons/close-active.svg");
}
.icon.icon-asc {
  background-image: url("../../../assets/images/icons/arrow-down-white.svg");
}
.icon.icon-checked {
  background-image: url("../../../assets/images/icons/download-grey.svg");
  cursor: pointer;
}
.icon.icon-checked:hover {
  background-image: url("../../../assets/images/icons/download-mgnt.svg");
}
.icon.icon-unchecked {
  background-image: url("../../../assets/images/icons/download-grey.svg");
  opacity: 0.8;
  cursor: pointer;
}
.icon.icon-unchecked:hover {
  background-image: url("../../../assets/images/icons/download-mgnt.svg");
  opacity: 0.8;
}
.icon.icon-infected {
  background-image: url("../../../assets/images/icons/attachment-infected.svg");
  opacity: 0.8;
  cursor: pointer;
}
.icon.icon-infected:hover {
  background-image: url("../../../assets/images/icons/attachment-infected.svg");
}
.icon.icon-failed {
  background-image: url("../../../assets/images/icons/attachment-failed.svg");
  opacity: 0.8;
  cursor: pointer;
}
.icon.icon-failed:hover {
  background-image: url("../../../assets/images/icons/attachment-failed.svg");
}
.icon.icon-psw_protected_document {
  background-image: url("../../../assets/images/icons/attachment-failed.svg");
  opacity: 0.8;
  cursor: pointer;
}
.icon.icon-psw_protected_document:hover {
  background-image: url("../../../assets/images/icons/attachment-failed.svg");
}
.icon.icon-desc {
  background-image: url("../../../assets/images/icons/arrow-up-white.svg");
}
.icon.icon-download {
  background-image: url("../../../assets/images/icons/download-grey.svg");
  cursor: pointer;
}
.icon.icon-download:hover {
  background-image: url("../../../assets/images/icons/download-mgnt.svg");
}
.icon.icon-delete {
  background-image: url("../../../assets/images/icons/delete-grey.svg");
  background-size: 50%;
  cursor: pointer;
}
.icon.icon-delete:hover {
  background-image: url("../../../assets/images/icons/delete-mgnt.svg");
}
.icon.icon-file {
  background-image: url("../../../assets/images/icons//evidence/other.svg");
  height: 3rem;
  width: 2.375rem;
}
.icon.icon-file.icon-bill {
  background-image: url("../../../assets/images/icons//evidence/bill.svg");
}
.icon.icon-file.icon-letter {
  background-image: url("../../../assets/images/icons//evidence/mail.svg");
}
.icon.icon-file.icon-photo {
  background-image: url("../../../assets/images/icons//evidence/photo.svg");
}
.icon.icon-file.icon-referral {
  background-image: url("../../../assets/images/icons//evidence/referral-from-supplier.svg");
}
.icon.icon-chevron-left, .icon.icon-chevron-right {
  background-image: url("../../../assets/images/icons//chevron-right.svg");
  cursor: pointer;
}
.icon.icon-chevron-left:hover, .icon.icon-chevron-right:hover {
  background-image: url("../../../assets/images/icons//chevron-right-active.svg");
}
.icon.icon-chevron-left {
  transform: rotate(180deg);
}
.icon.icon-refresh {
  background-image: url("../../../assets/images/icons//refresh.svg");
}
.icon.icon-spinner {
  background-image: url("../../../assets/images/icons//spinner.svg");
}
