.popup-window {
  box-sizing: border-box;
  background: var(--color-primary-contrast);
  border-radius: 4px;
  color: var(--color-primary);
  margin: 5rem 0;
  padding: 2rem 1rem;
}
.popup-window.limit-height {
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
}
.popup-window::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-dark);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}
.popup-window::-webkit-scrollbar {
  width: 16px;
}
.popup-window .form-group label {
  color: var(--color-primary);
  text-align: left;
}
.popup-window .popup-content {
  text-align: center;
}
.popup-window .popup-title {
  font-size: 1.5rem;
}
@media screen and (min-width: 35.5em) {
  .popup-window {
    left: 50%;
    margin-left: 0;
    margin-right: 0;
    padding: 5rem 6rem;
    position: absolute;
    transform: translate(-50%);
  }
  .popup-window .popup-content {
    margin-left: auto;
    margin-right: auto;
    width: 26.25rem;
  }
  .popup-window .popup-title {
    font-size: 1.75rem;
  }
}
