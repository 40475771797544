.btn {
  background: var(--color-button, var(--color-primary));
  border: 0;
  border-radius: 4px;
  color: var(--color-button-contrast, var(--color-primary-contrast));
  display: inline-block;
  font-weight: 500;
  font-size: 1.125rem;
  height: 3rem;
  line-height: 3rem;
  margin: 0;
  min-width: 160px;
  overflow: hidden;
  padding: 0 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  appearance: none;
  z-index: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn > span {
  position: relative;
  z-index: 2;
}
.btn:hover, .btn:focus {
  background: var(--color-button-hover, var(--color-secondary));
  color: var(--color-button-hover-contrast, var(--color-primary-contrast));
  text-decoration: none !important;
}
.btn-narrow {
  min-width: auto;
}
.btn-disabled, .btn:disabled {
  opacity: 0.6;
  pointer-events: none;
}
.btn-block {
  margin-left: 0;
  width: 100%;
}
.btn-transparent {
  background: transparent;
  color: var(--color-button, var(--color-primary));
  border: 0.125rem solid;
  line-height: 2.75rem;
}
.btn-transparent.btn-small {
  line-height: 1.75rem;
}
.btn-transparent:hover, .btn-transparent:focus {
  background: var(--color-button-hover, var(--color-secondary));
  border-color: var(--color-button-hover, var(--color-secondary));
  color: var(--color-button-hover-contrast, var(--color-primary-contrast));
}
.btn-small {
  font-size: 0.875rem;
  height: 2rem;
  line-height: 2rem;
  min-width: auto;
  font-weight: 500;
}
.btn-add > span:before {
  content: "+";
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 0.5rem 0 -0.5rem;
  vertical-align: sub;
  font-family: Arboria, Arial, sans-serif;
}
.btn-delete {
  background: #c80a0a;
  color: #fff;
}
.btn-delete > span:before {
  content: "+";
  transform: rotate(45deg);
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 0.5rem 0 -0.5rem;
  vertical-align: middle;
  font-family: Arboria, Arial, sans-serif;
}
.btn-delete:before, .btn-delete:after {
  background-color: #c80a0a;
}
.btn-delete:hover, .btn-delete:focus {
  background: rgb(228.5714285714, 11.4285714286, 11.4285714286);
  color: #fff;
}
.btn-attach > span:before {
  content: url("../../../assets/images/icons/paperclip-invert.svg");
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 0.5rem 0 -0.5rem;
  vertical-align: sub;
  font-family: Arboria, Arial, sans-serif;
}
.btn-attach.btn-transparent > span:before {
  content: url("../../../assets/images/icons/paperclip.svg");
}
.btn-texted {
  background: none;
  border: none;
  color: var(--color-button, var(--color-primary));
}
.btn-texted:before, .btn-texted:after {
  display: none;
}
.btn-texted:hover {
  background: none;
  color: var(--color-button, var(--color-primary));
}
.btn-proceed > span {
  color: var(--color-button-contrast, var(--color-primary-contrast));
}
