.dev-banner {
  backdrop-filter: blur(2px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  z-index: 999;
  background: #EEE;
  border: 1px solid #BBB;
  font-size: 0.85rem;
  padding: 0.1em 0.8em;
  user-select: none;
  text-wrap: nowrap;
  top: 0;
  left: 0;
}
@media screen and (min-width: 48em) {
  .dev-banner {
    left: auto;
    right: 0;
  }
}
.dev-banner:hover .dev-banner-drag {
  opacity: 0.5;
}
.dev-banner--local {
  background: #FFE0B9;
  border-color: #EFBAB3;
}
.dev-banner--local .dev-banner-icon {
  color: #7d4f0f;
}
.dev-banner--staging {
  background: #E0F6BC;
  border-color: #AFDF91;
}
.dev-banner--staging .dev-banner-icon {
  color: #2f5717;
}
.dev-banner--academy {
  background: #DAFDFF;
  border-color: #95D7F4;
}
.dev-banner--academy .dev-banner-icon {
  color: #0e536d;
}
.dev-banner-name {
  text-transform: capitalize;
  font-weight: bold;
}
.dev-banner button {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.dev-banner button svg {
  display: block;
}
.dev-banner-close {
  margin-left: auto;
}
