.profile-tab {
  padding: 0.5rem 1rem;
  position: relative;
}
.profile-tab .profile-initials {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  color: #ffffff;
  cursor: pointer;
  font-family: Arboria, Arial, sans-serif;
  font-size: 0.75rem;
  height: 2rem;
  line-height: 30px;
  margin: 0 auto;
  text-align: center;
  width: 2rem;
}
.profile-tab .profile-modal {
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  position: absolute;
  right: 1rem;
  top: 4rem;
  width: 230px;
  z-index: 3;
}
.profile-tab .profile-modal:before {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: -3px 3px 5px 0 var(--color-shadow, rgba(0, 0, 0, 0.2));
  content: "";
  display: block;
  height: 20px;
  position: absolute;
  right: 0.5rem;
  top: -0.5rem;
  transform: rotate(130deg);
  width: 20px;
}
.profile-tab .profile-modal ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.profile-tab .profile-modal ul li {
  display: block;
  font-size: 0.875rem;
  padding: 0.5rem 0;
}
.profile-tab .profile-modal ul li:last-child {
  padding-bottom: 0;
}
.profile-tab .profile-modal .profile-modal-close {
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
}
@media screen and (min-width: 48em) {
  .profile-tab {
    padding: 0 0 1rem;
  }
  .profile-tab .profile-modal {
    left: 4rem;
    right: auto;
    top: -0.5rem;
  }
  .profile-tab .profile-modal:before {
    left: -8px;
    right: auto;
    top: 1rem;
    transform: rotate(45deg);
  }
}
